.button {
  display: inline-block;
  padding: 5px 15px;
  font-size: 13px;
  line-height: inherit;
  color: inherit;
  text-align: center;
  // white-space: nowrap;
  user-select: none;
  background-color: transparent;
  border: 1px solid map-get(map-get($color-grayscale, ""), "border-light");
}

.button.disabled,
.button:disabled {
  // background-color: map-get(map-get($color-grayscale, "er"), "bg-light");
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNikAQAACIAHF/uBd8AAAAASUVORK5CYII="), url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6bwwAAjoBNT578agAAAAASUVORK5CYII=");
  background-repeat: repeat;
  opacity: .75;
}

.button.active,
.button:active {
  filter: contrast(120%);
}

.button:not([disabled]):not(.disabled) {
  cursor: pointer;
}

.button.disabled,
[disabled] .button {
  pointer-events: none;
}

.button-xs {
  padding: 2px 10px;
  font-size: 12px;
}

.button-sm {
  padding: 5px 15px;
  font-size: 13px;
}

.button-md {
  padding: 10px 20px;
  font-size: 14px;
}

.button-lg {
  padding: 15px 25px;
  font-size: 18px;
}

.button-block {
  display: block;
  width: 100%;
}

@media (max-width: 767px) {
  .m-button-block {
    display: block;
    width: 100%;
  }
}
