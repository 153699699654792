.clearfix {
  @include clearfix();
}
.pull-left,
.pull-right,
.pull-none, {
  @include text-wrap();
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.pull-none {
  float: none !important;
}
@media (max-width: 767px) {
  .m-pull-left,
  .m-pull-right,
  .m-pull-none, {
    @include text-wrap();
  }
  .m-pull-right {
    float: right !important;
  }
  .m-pull-left {
    float: left !important;
  }
  .m-pull-none {
    float: none !important;
  }
}
