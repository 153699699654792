// stylelint-disable selector-no-qualifying-type

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px 15px;
  vertical-align: top;
}
.table thead th {
  font-weight: inherit;
  text-align: left;
  vertical-align: bottom;
}

.table tr:not(:first-child) {
  border-top: 1px solid map-get(map-get($color-grayscale, "er"), "border-light");
}
.table.table-no-line tr:not(:first-child) {
  border-top: 0 solid map-get(map-get($color-grayscale, "er"), "border-light");
}
.table thead tr:last-child {
  border-bottom: 1px solid map-get(map-get($color-grayscale, ""), "border-light");
}
.table tbody + tbody {
  border-top: 1px solid map-get(map-get($color-grayscale, ""), "border-light");
}

.table-bordered {
  border: 1px solid map-get(map-get($color-grayscale, "er"), "border-light");
}
.table-bordered th:not(:last-child),
.table-bordered td:not(:last-child) {
  border-right: 1px solid map-get(map-get($color-grayscale, "er"), "border-light");
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .02);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, .04);
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

th.th-nowrap,
td.td-nowrap {
  white-space: nowrap;
}

th.th-wrap,
td.td-wrap {
  white-space: normal;
}

.table-text-middle tbody td {
  vertical-align: middle;
}
