.has-fancy-corner {
  position: relative;
  overflow: hidden;
}

.corner-light.top-left::before,
.corner-light.top-right::before,
.corner-light.bottom-left::before,
.corner-light.bottom-right::before,
.corner-dark.top-left::before,
.corner-dark.top-right::before,
.corner-dark.bottom-left::before,
.corner-dark.bottom-right::before {
  position: absolute;
  width: 70px;
  height: 70px;
  content: "";
  transform: rotate(45deg);
}
.corner-light::before {
  background-color: rgba(255, 255, 255, .1);
}
.corner-dark::before {
  background-color: rgba(0, 0, 0, .1);
}
.corner-light.top-left::before,
.corner-dark.top-left::before {
  top: -35px;
  left: -35px;
}
.corner-light.top-right::before,
.corner-dark.top-right::before {
  top: -35px;
  right: -35px;
}
.corner-light.bottom-left::before,
.corner-dark.bottom-left::before {
  bottom: -35px;
  left: -35px;
}
.corner-light.bottom-right::before,
.corner-dark.bottom-right::before {
  right: -35px;
  bottom: -35px;
}
