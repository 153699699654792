@each $name, $values in $color-grayscale {
  .bg-light#{$name} {
    color: map-get(map-get($color-grayscale, "est"), "text-gray");
    background-color: map-get($values, "bg-light");
  }
  .bg-gray#{$name} {
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg-gray");
  }
  .bg-dark#{$name} {
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg-dark");
  }
}
@each $name, $values in $color {
  .bg-light-#{$name} {
    color: map-get($values, "text");
    background-color: map-get($values, "bg-light");
  }
  .bg-#{$name} {
    // color: map-get($values, "text-light");
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg");
  }
  .bg-dark-#{$name} {
    // color: map-get($values, "text-light");
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg-dark");
  }
}

@each $name, $values in $color-grayscale {
  .hover-bg-light#{$name}:hover,
  .submenu-bg-light#{$name} > li li > a,
  .menu-active-bg-light#{$name} > .active > a,
  .submenu-active-bg-light#{$name} > li .active > a,
  .menu-hover-bg-light#{$name} > li > a:hover,
  .submenu-hover-bg-light#{$name} > li li > a:hover {
    color: map-get(map-get($color-grayscale, "est"), "text-gray");
    background-color: map-get($values, "bg-light");
  }
  .hover-bg-gray#{$name}:hover,
  .submenu-bg-gray#{$name} > li li > a,
  .menu-active-bg-gray#{$name} > .active > a,
  .submenu-active-bg-gray#{$name} > li .active > a,
  .menu-hover-bg-gray#{$name} > li > a:hover,
  .submenu-hover-bg-gray#{$name} > li li > a:hover {
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg-gray");
  }
  .hover-bg-dark#{$name}:hover,
  .submenu-bg-dark#{$name} > li li > a,
  .menu-active-bg-dark#{$name} > .active > a,
  .submenu-active-bg-dark#{$name} > li .active > a,
  .menu-hover-bg-dark#{$name} > li > a:hover,
  .submenu-hover-bg-dark#{$name} > li li > a:hover {
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg-dark");
  }
}
@each $name, $values in $color {
  .hover-bg-light-#{$name}:hover,
  .submenu-bg-light-#{$name} > li li > a,
  .menu-active-bg-light-#{$name} > .active > a,
  .submenu-active-bg-light-#{$name} > li .active > a,
  .menu-hover-bg-light-#{$name} > li > a:hover,
  .submenu-hover-bg-light-#{$name} > li li > a:hover {
    color: map-get($values, "text");
    background-color: map-get($values, "bg-light");
  }
  .hover-bg-#{$name}:hover,
  .submenu-bg-#{$name} > li li > a,
  .menu-active-bg-#{$name} > .active > a,
  .submenu-active-bg-#{$name} > li .active > a,
  .menu-hover-bg-#{$name} > li > a:hover,
  .submenu-hover-bg-#{$name} > li li > a:hover {
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg");
  }
  .hover-bg-dark-#{$name}:hover,
  .submenu-bg-dark-#{$name} > li li > a,
  .menu-active-bg-dark-#{$name} > .active > a,
  .submenu-active-bg-dark-#{$name} > li .active > a,
  .menu-hover-bg-dark-#{$name} > li > a:hover,
  .submenu-hover-bg-dark-#{$name} > li li > a:hover {
    color: map-get(map-get($color-grayscale, "est"), "text-light");
    background-color: map-get($values, "bg-dark");
  }
}

.bg-transparent,
.hover-bg-transparent:hover {
  background-color: transparent;
}
