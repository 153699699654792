.lighter-10,
.hover-lighter-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6LwkAAiABG+faPgsAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.lighter-20,
.hover-lighter-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6bwwAAjoBNT578agAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.lighter-30,
.hover-lighter-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP67wMAAlMBTsfq8ZQAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.lighter-40,
.hover-lighter-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6nwYAAm0BaIe3/WsAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.lighter-50,
.hover-lighter-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6Xw8AAoYBgRDEVrUAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.darker-10,
.hover-darker-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNikAQAACIAHF/uBd8AAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.darker-20,
.hover-darker-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiMAYAADwANpiOMBYAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.darker-30,
.hover-darker-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNi8AEAAFUATydJphIAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.darker-40,
.hover-darker-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiSAMAAG8AaYLq42UAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.darker-50,
.hover-darker-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiqAcAAIgAgoGu+rEAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.red-10,
.hover-red-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4MzMnjJZUAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.red-20,
.hover-red-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4MzMnjJZUAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.red-30,
.hover-red-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4MzMnjJZUAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.red-40,
.hover-red-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4MzMnjJZUAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.red-50,
.hover-red-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4MzMnjJZUAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.orange-10,
.hover-orange-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4djOqgi0UAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.orange-20,
.hover-orange-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4djOqgi0UAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.orange-30,
.hover-orange-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4djOqgi0UAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.orange-40,
.hover-orange-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4djOqgi0UAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.orange-50,
.hover-orange-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4djOqgi0UAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.yellow-10,
.hover-yellow-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4uDP/+9fUAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.yellow-20,
.hover-yellow-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4uDP/+9fUAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.yellow-30,
.hover-yellow-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4uDP/+9fUAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.yellow-40,
.hover-yellow-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4uDP/+9fUAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.yellow-50,
.hover-yellow-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4uDP/+9fUAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.chartreuse-10,
.hover-chartreuse-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2uDNkymyeAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.chartreuse-20,
.hover-chartreuse-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2uDNkymyeAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.chartreuse-30,
.hover-chartreuse-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2uDNkymyeAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.chartreuse-40,
.hover-chartreuse-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2uDNkymyeAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.chartreuse-50,
.hover-chartreuse-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2uDNkymyeAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.green-10,
.hover-green-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuDMSmyO1AAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.green-20,
.hover-green-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuDMSmyO1AAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.green-30,
.hover-green-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuDMSmyO1AAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.green-40,
.hover-green-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuDMSmyO1AAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.green-50,
.hover-green-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuDMSmyO1AAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.spring-10,
.hover-spring-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuHYULZaqAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.spring-20,
.hover-spring-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuHYULZaqAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.spring-30,
.hover-spring-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuHYULZaqAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.spring-40,
.hover-spring-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuHYULZaqAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.spring-50,
.hover-spring-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuHYULZaqAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.cyan-10,
.hover-cyan-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuLho8XkdAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.cyan-20,
.hover-cyan-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuLho8XkdAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.cyan-30,
.hover-cyan-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuLho8XkdAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.cyan-40,
.hover-cyan-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuLho8XkdAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.cyan-50,
.hover-cyan-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzuLho8XkdAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.azure-10,
.hover-azure-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzdrg9iIPdAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.azure-20,
.hover-azure-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzdrg9iIPdAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.azure-30,
.hover-azure-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzdrg9iIPdAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.azure-40,
.hover-azure-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzdrg9iIPdAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.azure-50,
.hover-azure-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzdrg9iIPdAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.blue-10,
.hover-blue-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzM7iwhjidAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.blue-20,
.hover-blue-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzM7iwhjidAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.blue-30,
.hover-blue-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzM7iwhjidAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.blue-40,
.hover-blue-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzM7iwhjidAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.blue-50,
.hover-blue-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUzM7iwhjidAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.violet-10,
.hover-violet-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2M7jG13e2AAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.violet-20,
.hover-violet-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2M7jG13e2AAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.violet-30,
.hover-violet-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2M7jG13e2AAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.violet-40,
.hover-violet-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2M7jG13e2AAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.violet-50,
.hover-violet-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEV2M7jG13e2AAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.magenta-10,
.hover-magenta-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M7hd5sz8AAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.magenta-20,
.hover-magenta-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M7hd5sz8AAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.magenta-30,
.hover-magenta-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M7hd5sz8AAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.magenta-40,
.hover-magenta-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M7hd5sz8AAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.magenta-50,
.hover-magenta-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M7hd5sz8AAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.rose-10,
.hover-rose-10:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M3YhOiNLAAAAAXRSTlMZJI1wpgAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.rose-20,
.hover-rose-20:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M3YhOiNLAAAAAXRSTlMz/za5cAAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.rose-30,
.hover-rose-30:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M3YhOiNLAAAAAXRSTlNMP4zV3QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.rose-40,
.hover-rose-40:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M3YhOiNLAAAAAXRSTlNm5DccCwAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
.rose-50,
.hover-rose-50:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEW4M3YhOiNLAAAAAXRSTlN/gFy0ywAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=");
  background-repeat: repeat;
}
