@function hsb($hue, $saturation, $brightness, $a: 1) {
  @if $brightness == 0 {
    @return hsla(0, 0, 0, $a);
  } @else {
    $lightness: ($brightness/2) * (2 - ($saturation/100));
    @if $lightness == 100 {
      @return hsla($hue, 0, $lightness, $a);
    }
    $saturation-hsl: ($brightness * $saturation) / if($lightness < 50, $lightness * 2, 200 - $lightness * 2);
    @return hsla($hue, $saturation-hsl, $lightness, $a);
  }
}

// bg-light* has different color than text and border
$color-grayscale: () !default;
$color-grayscale: map-merge(
  (
    ""           : (
      "bg-light" : hsb(0, 0, 96),
      "text-light"    : hsb(0, 0, 86),
      "border-light"    : hsb(0, 0, 86),
      //
      "bg-gray"     : hsb(0, 0, 49),
      "text-gray"     : hsb(0, 0, 53),
      "border-gray"     : hsb(0, 0, 49),
      //
      "bg-dark"     : hsb(0, 0, 21),
      "text-dark"     : hsb(0, 0, 21),
      "border-dark"     : hsb(0, 0, 21),
    ),
    "er"         : (
      "bg-light" : hsb(0, 0, 98),
      "text-light"    : hsb(0, 0, 93),
      "border-light"    : hsb(0, 0, 93),
      //
      "bg-gray"     : hsb(0, 0, 42),
      "text-gray"     : hsb(0, 0, 44),
      "border-gray"     : hsb(0, 0, 42),
      //
      "bg-dark"     : hsb(0, 0, 14),
      "text-dark"     : hsb(0, 0, 14),
      "border-dark"     : hsb(0, 0, 14),
    ),
    "est"        : (
      "bg-light" : hsb(0, 0, 100),
      "text-light"    : hsb(0, 0, 100),
      "border-light"    : hsb(0, 0, 100),
      //
      "bg-gray"     : hsb(0, 0, 35),
      "text-gray"     : hsb(0, 0, 35),
      "border-gray"     : hsb(0, 0, 35),
      //
      "bg-dark"     : hsb(0, 0, 7),
      "text-dark"     : hsb(0, 0, 7),
      "border-dark"     : hsb(0, 0, 7),
    ),
  ),
  $color-grayscale
);

$color: () !default;
$color: map-merge(
  (
    "red"            : (
      "bg-light"     : hsb(0, 5, 98),
      "text-light"   : hsb(0, 35, 95),
      "border-light" : hsb(0, 20, 100),
      //
      "bg"           : hsb(0, 72, 72),
      "text"         : hsb(0, 72, 72),
      "border"       : hsb(0, 72, 72),
      //
      "bg-dark"      : hsb(0, 50, 40),
      "text-dark"    : hsb(0, 50, 40),
      "border-dark"  : hsb(0, 50, 40),
    ),
    "orange"         : (
      "bg-light"     : hsb(30, 5, 98),
      "text-light"   : hsb(30, 35, 95),
      "border-light" : hsb(30, 20, 100),
      //
      "bg"           : hsb(30, 72, 72),
      "text"         : hsb(30, 72, 72),
      "border"       : hsb(30, 72, 72),
      //
      "bg-dark"      : hsb(30, 50, 40),
      "text-dark"    : hsb(30, 50, 40),
      "border-dark"  : hsb(30, 50, 40),
    ),
    "yellow"         : (
      "bg-light"     : hsb(60, 5, 98),
      "text-light"   : hsb(60, 35, 95),
      "border-light" : hsb(60, 20, 100),
      //
      "bg"           : hsb(60, 72, 72),
      "text"         : hsb(60, 72, 72),
      "border"       : hsb(60, 72, 72),
      //
      "bg-dark"      : hsb(60, 50, 40),
      "text-dark"    : hsb(60, 50, 40),
      "border-dark"  : hsb(60, 50, 40),
    ),
    "chartreuse": (
      "bg-light"     : hsb(90, 5, 98),
      "text-light"   : hsb(90, 35, 95),
      "border-light" : hsb(90, 20, 100),
      //
      "bg"           : hsb(90, 72, 72),
      "text"         : hsb(90, 72, 72),
      "border"       : hsb(90, 72, 72),
      //
      "bg-dark"      : hsb(90, 50, 40),
      "text-dark"    : hsb(90, 50, 40),
      "border-dark"  : hsb(90, 50, 40),
    ),
    "green"          : (
      "bg-light"     : hsb(120, 5, 98),
      "text-light"   : hsb(120, 35, 95),
      "border-light" : hsb(120, 20, 100),
      //
      "bg"           : hsb(120, 72, 72),
      "text"         : hsb(120, 72, 72),
      "border"       : hsb(120, 72, 72),
      //
      "bg-dark"      : hsb(120, 50, 40),
      "text-dark"    : hsb(120, 50, 40),
      "border-dark"  : hsb(120, 50, 40),
    ),
    "spring"         : (
      "bg-light"     : hsb(150, 5, 98),
      "text-light"   : hsb(150, 35, 95),
      "border-light" : hsb(150, 20, 100),
      //
      "bg"           : hsb(150, 72, 72),
      "text"         : hsb(150, 72, 72),
      "border"       : hsb(150, 72, 72),
      //
      "bg-dark"      : hsb(150, 50, 40),
      "text-dark"    : hsb(150, 50, 40),
      "border-dark"  : hsb(150, 50, 40),
    ),
    "cyan"           : (
      "bg-light"     : hsb(180, 5, 98),
      "text-light"   : hsb(180, 35, 95),
      "border-light" : hsb(180, 20, 100),
      //
      "bg"           : hsb(180, 72, 72),
      "text"         : hsb(180, 72, 72),
      "border"       : hsb(180, 72, 72),
      //
      "bg-dark"      : hsb(180, 50, 40),
      "text-dark"    : hsb(180, 50, 40),
      "border-dark"  : hsb(180, 50, 40),
    ),
    "azure"          : (
      "bg-light"     : hsb(210, 5, 98),
      "text-light"   : hsb(210, 35, 95),
      "border-light" : hsb(210, 20, 100),
      //
      "bg"           : hsb(210, 72, 72),
      "text"         : hsb(210, 72, 72),
      "border"       : hsb(210, 72, 72),
      //
      "bg-dark"      : hsb(210, 50, 40),
      "text-dark"    : hsb(210, 50, 40),
      "border-dark"  : hsb(210, 50, 40),
    ),
    "blue"           : (
      "bg-light"     : hsb(240, 5, 98),
      "text-light"   : hsb(240, 35, 95),
      "border-light" : hsb(240, 20, 100),
      //
      "bg"           : hsb(240, 72, 72),
      "text"         : hsb(240, 72, 72),
      "border"       : hsb(240, 72, 72),
      //
      "bg-dark"      : hsb(240, 50, 40),
      "text-dark"    : hsb(240, 50, 40),
      "border-dark"  : hsb(240, 50, 40),
    ),
    "violet"         : (
      "bg-light"     : hsb(270, 5, 98),
      "text-light"   : hsb(270, 35, 95),
      "border-light" : hsb(270, 20, 100),
      //
      "bg"           : hsb(270, 72, 72),
      "text"         : hsb(270, 72, 72),
      "border"       : hsb(270, 72, 72),
      //
      "bg-dark"      : hsb(270, 50, 40),
      "text-dark"    : hsb(270, 50, 40),
      "border-dark"  : hsb(270, 50, 40),
    ),
    "magenta"        : (
      "bg-light"     : hsb(300, 5, 98),
      "text-light"   : hsb(300, 35, 95),
      "border-light" : hsb(300, 20, 100),
      //
      "bg"           : hsb(300, 72, 72),
      "text"         : hsb(300, 72, 72),
      "border"       : hsb(300, 72, 72),
      //
      "bg-dark"      : hsb(300, 50, 40),
      "text-dark"    : hsb(300, 50, 40),
      "border-dark"  : hsb(300, 50, 40),
    ),
    "rose"           : (
      "bg-light"     : hsb(330, 5, 98),
      "text-light"   : hsb(330, 35, 95),
      "border-light" : hsb(330, 20, 100),
      //
      "bg"           : hsb(330, 72, 72),
      "text"         : hsb(330, 72, 72),
      "border"       : hsb(330, 72, 72),
      //
      "bg-dark"      : hsb(330, 50, 40),
      "text-dark"    : hsb(330, 50, 40),
      "border-dark"  : hsb(330, 50, 40),
    ),
  ),
  $color
);

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin text-wrap() {
  max-width: 100%;
  word-break: normal;
  word-wrap: break-word;
  white-space: normal;
}
