.has-bg-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-position: 50% 50%;
  background-size: 100% auto;
  background-size: cover;
}
.bg-img-repeat {
  background-repeat: repeat;
  background-size: auto auto;
}
.bg-img-fixed {
  background-attachment: fixed;
}
