.form-wrapper {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
  vertical-align: top;
}

.form-text,
.form-textarea,
.form-dropdown {
  display: block;
  width: 100%;
  padding: 5px 15px;
  font-size: 13px;
  line-height: inherit;
  color: inherit;
  vertical-align: top;
  background-color: map-get(map-get($color-grayscale, "est"), "border-light");
  background-clip: padding-box;
  border: 1px solid map-get(map-get($color-grayscale, ""), "border-light");

  &:focus {
    border-color: lighten(map-get(map-get($color, "azure"), "border"), 20%);
    outline: 0;
  }

  &::placeholder {
    color: map-get(map-get($color-grayscale, ""), "text-gray");
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: map-get(map-get($color-grayscale, "er"), "bg-light");
    opacity: 1;
  }
}

.form-icon {
  position: relative;
  font-size: 13px;
}

.form-icon > .icon-prepend,
.form-icon > .icon-append {
  position: absolute;
  top: 0;
  padding: 5px 15px;
  margin: 1px;
  line-height: inherit;
  text-align: center;
}

.form-icon > .icon-prepend {
  left: 0;
}
.form-icon > .icon-append {
  right: 0;
}

.icon-prepend ~ .form-text,
.icon-prepend ~ .form-textarea {
  padding-left: 40px;
}

.icon-append ~ .form-text,
.icon-append ~ .form-textarea {
  padding-right: 40px;
}

.form-dropdown {
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus::-ms-value {
    color: map-get(map-get($color-grayscale, "est"), "text-gray");
    background-color: transparent;
  }
}

.form-checkbox,
.form-radio,
.form-file,
.form-static {
  display: block;
  vertical-align: top;
}

.form-checkbox [type="checkbox"],
.form-radio [type="radio"] {
  margin-right: 5px;
}

.form-checkbox label,
.form-radio label {
  display: block;
  margin-bottom: 5px;
}

.form-checkbox-inline label,
.form-radio-inline label {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 0;
}

.form-info {
  margin-top: 2px;
  font-size: 90%;
  color: map-get(map-get($color-grayscale, ""), "text-gray");
}

//form inline
.form-inline .form-label {
  display: inline-block;
  width: auto;
  margin-right: 5px;
}
.form-inline .form-text,
.form-inline .form-textarea,
.form-inline .form-dropdown,
.form-inline .form-checkbox,
.form-inline .form-radio,
.form-inline .form-file,
.form-inline .form-static {
  display: inline-block;
  width: auto;
  margin-right: 15px;
}

// elegang/fancy checkbox & radio
.form-checkbox-elegant [type="checkbox"],
.form-checkbox-fancy [type="checkbox"],
.form-radio-elegant [type="radio"],
.form-radio-fancy [type="radio"] {
  display: none;
}
.form-checkbox-elegant input + i,
.form-checkbox-fancy input + i,
.form-radio-elegant input + i,
.form-radio-fancy input + i {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  font-size: 14px;
  vertical-align: middle;
}
.form-radio-elegant input + i,
.form-radio-fancy input + i {
  border-radius: 50%;
}
.form-checkbox-elegant label:hover input + i,
.form-radio-elegant label:hover input + i,
.form-checkbox-fancy label:hover input + i,
.form-radio-fancy label:hover input + i {
  background-color: map-get(map-get($color-grayscale, "er"), "bg-light");
}
.form-checkbox-elegant label:active input + i,
.form-radio-elegant label:active input + i,
.form-checkbox-fancy label:active input + i,
.form-radio-fancy label:active input + i {
  background-color: hsb(0, 0, 92);
}


.form-checkbox-elegant input + i::after,
.form-checkbox-fancy input + i::after,
.form-radio-elegant input + i::after,
.form-radio-fancy input + i::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-style: normal;
  line-height: 14px;
  text-align: center;
  content: "";
  border: 1px solid map-get(map-get($color-grayscale, ""), "border-gray");
}
.form-checkbox-elegant input + i::after,
.form-radio-elegant input + i::after {
  color: map-get(map-get($color, "azure"), "text");
}
.form-checkbox-fancy input + i::after,
.form-radio-fancy input + i::after {
  color: map-get(map-get($color-grayscale, "est"), "text-light");
}
.form-checkbox-elegant input + i::after,
.form-checkbox-fancy input + i::after {
  font-size: 80%;
}
.form-radio-elegant input + i::after,
.form-radio-fancy input + i::after {
  font-size: 65%;
  border-radius: 50%;
}

.form-checkbox-elegant label input:hover + i::after,
.form-radio-elegant label input:hover + i::after,
.form-checkbox-fancy label input:hover + i::after,
.form-radio-fancy label input:hover + i::after {
  border-color: map-get(map-get($color, "azure"), "border");
}
.form-checkbox-elegant label:active input + i::after,
.form-radio-elegant label:active input + i::after,
.form-checkbox-fancy label:active input + i::after,
.form-radio-fancy label:active input + i::after {
  background-image: linear-gradient(rgba(map-get(map-get($color-grayscale, ""), "bg-light"), .2), rgba(map-get(map-get($color-grayscale, ""), "bg-light"), .2));
}
.form-checkbox-elegant input:checked + i::after,
.form-radio-elegant input:checked + i::after,
.form-checkbox-fancy input:checked + i::after,
.form-radio-fancy input:checked + i::after {
  border-color: map-get(map-get($color, "azure"), "border");
}
.form-checkbox-fancy input:checked + i::after,
.form-radio-fancy input:checked + i::after {
  background-color: map-get(map-get($color, "azure"), "bg");
}

.form-checkbox-elegant input:checked + i::after,
.form-checkbox-fancy input:checked + i::after {
  content: "✔";
}
.form-radio-elegant input:checked + i::after,
.form-radio-fancy input:checked + i::after {
  content: "⬤";
}
.form-checkbox-elegant label input:disabled + i::after,
.form-radio-elegant label input:disabled + i::after,
.form-checkbox-fancy label input:disabled + i::after,
.form-radio-fancy label input:disabled + i::after {
  opacity: .75;
}

// validation
.has-success {
  & .form-label,
  & .form-info {
    color: darken(map-get(map-get($color, "green"), "text"), 20%);
  }

  & .form-text,
  & .form-textarea,
  & .form-dropdown {
    border-color: darken(map-get(map-get($color, "green"), "border"), 20%);
  }
}
.has-warning {
  & .form-label,
  & .form-info {
    color: map-get(map-get($color, "orange"), "text");
  }

  & .form-text,
  & .form-textarea,
  & .form-dropdown {
    border-color: darken(map-get(map-get($color, "orange"), "border"), 20%);
  }
}
.has-error {
  & .form-label,
  & .form-info {
    color: map-get(map-get($color, "red"), "text");
  }

  & .form-text,
  & .form-textarea,
  & .form-dropdown {
    border-color: darken(map-get(map-get($color, "red"), "border"), 20%);
  }
}

// form size
.form-xs:not(.form-icon),
.form-xs .form-text,
.form-xs .form-textarea,
.form-xs .form-dropdown {
  padding: 2px 10px;
  font-size: 12px;
}

.form-sm:not(.form-icon),
.form-sm .form-text,
.form-sm .form-textarea,
.form-sm .form-dropdown {
  padding: 5px 15px;
  font-size: 13px;
}

.form-md:not(.form-icon),
.form-md .form-text,
.form-md .form-textarea,
.form-md .form-dropdown {
  padding: 10px 20px;
  font-size: 14px;
}

.form-lg:not(.form-icon),
.form-lg .form-text,
.form-lg .form-textarea,
.form-lg .form-dropdown {
  padding: 15px 25px;
  font-size: 18px;
}
// form icon with size
.form-xs.form-icon .icon-prepend,
.form-xs.form-icon .icon-append,
.form-xs .form-text,
.form-xs .form-textarea {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
}
.form-sm.form-icon .icon-prepend,
.form-sm.form-icon .icon-append,
.form-sm .form-text,
.form-sm .form-textarea {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
}
.form-md.form-icon .icon-prepend,
.form-md.form-icon .icon-append,
.form-md .form-text,
.form-md .form-textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}
.form-lg.form-icon .icon-prepend,
.form-lg.form-icon .icon-append,
.form-lg .form-text,
.form-lg .form-textarea {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}

.form-xs.form-icon .icon-prepend,
.form-xs.form-icon .icon-append {
  padding-right: 15px;
  padding-left: 15px;
}
.form-sm.form-icon .icon-prepend,
.form-sm.form-icon .icon-append {
  padding-right: 15px;
  padding-left: 15px;
}
.form-md.form-icon .icon-prepend,
.form-md.form-icon .icon-append {
  padding-right: 15px;
  padding-left: 15px;
}
.form-lg.form-icon .icon-prepend,
.form-lg.form-icon .icon-append {
  padding-right: 20px;
  padding-left: 20px;
}

.form-xs .icon-prepend ~ .form-text,
.form-xs .icon-prepend ~ .form-textarea {
  padding-left: 35px;
}
.form-sm .icon-prepend ~ .form-text,
.form-sm .icon-prepend ~ .form-textarea {
  padding-left: 37.5px;
}
.form-md .icon-prepend ~ .form-text,
.form-md .icon-prepend ~ .form-textarea {
  padding-left: 40px;
}
.form-lg .icon-prepend ~ .form-text,
.form-lg .icon-prepend ~ .form-textarea {
  padding-left: 50px;
}

.form-xs .icon-append ~ .form-text,
.form-xs .icon-append ~ .form-textarea {
  padding-right: 35px;
}
.form-sm .icon-append ~ .form-text,
.form-sm .icon-append ~ .form-textarea {
  padding-right: 37.5px;
}
.form-md .icon-append ~ .form-text,
.form-md .icon-append ~ .form-textarea {
  padding-right: 40px;
}
.form-lg .icon-append ~ .form-text,
.form-lg .icon-append ~ .form-textarea {
  padding-right: 50px;
}
